//JS zo veel mogelijk opsplitsen in modules. Dit bevordert de leesbaarheid en vergemakkelijkt het onderhoud.

//Bovenaan dit document modules/plugins requiren via volgende methode:

require("./plugins/bootstrap.js");
require("./plugins/slick.js");
require("./plugins/magnific-popup.js");
require("./plugins/bootstrap-datepicker.js");

require("./modules/slider.js");
require("./modules/popup.js");
require("./modules/forms.js");
require("./modules/menu.js");
require("./modules/mobile-nav.js");
require("./modules/reviews.js");
require("./modules/fastbooker.js");
if ($('#maps').length){
require("./modules/location.js");
}
